

import whychoose from "../../assests/images/accruo-consultant-banner2.png"
import cleantel from "../../assests/images/cleantel.png"
import op3 from "../../assests/images/op-3.png"
import steelmart from "../../assests/images/steelmart.png"
import React, { useRef, useEffect } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./index.css"

const OurTestimonialsPage = () => {
  //design

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,

      slidesToSlide: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
      slidesToSlide: 2
    },

    tablet: {
      breakpoint: { max: 1300, min: 880 },
      items: 2,
      slidesToSlide: 2

    },
    mobile: {
      breakpoint: { max: 880, min: 0 },
      items: 1
    }
  };










  return (<>

    <section>
      <div className="head-main">
        <h5>Why choose Accruon</h5>

      </div>
      <div className="row justify-content-center">

        <div className="col-lg-6 col-md-12 md-pb">
          <img src={whychoose} className="about1-img" />
        </div>

        <div className="col-lg-6 col-md-12 md-pb">
          <div className="title-wrapper align-left">

            <p className="para-one">We are a highly experienced team of trained professionals dedicated to delivering top-tier
              accounting services. Our expertise in this field is unparalleled, and we invite you to engage with our specialists to experience our exceptional service.
              Our primary objective is to provide unwavering support, valuable advice, and expert guidance
              throughout your evolving business journey and personal life. You can always count on our
              seasoned staff to deliver timely services at a reasonable cost. We are committed to staying up-
              to-date with the latest industry developments to identify optimal opportunities for your benefit.
              We communicate complex issues in plain language, ensuring your comprehension of the best
              strategies to achieve your objectives. This, coupled with our extensive business acumen and
              meticulous attention to detail, ensures you receive the ideal solutions for your unique
              challenges</p>

            <p className="about-p1">
              Take advantage of our team's technical prowess to elevate your position. Our business owners
              and tax managers each boast more than two decades of experience in private practice,
              commerce, and revenue departments. This wealth of knowledge is at your disposal to
              guarantee you receive the finest advice and dedicated attention.</p>

          </div>
        </div>


      </div>
    </section>



    {/* <!--Testimonial--> */}
    <section>
      <div className="head-main" id="head-1">
        <h5>Our Testimonials</h5>

      </div>

      <Carousel responsive={responsive} showDots={true} autoPlay={true} autoPlaySpeed={3400} infinite={true} rewind={true}>




        <div className="row row-1">
          <div className="column-1">
            <div className="testimonial-card">
              <svg className="union" viewBox="0 0 358 433" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32 0C14.3269 0 0 14.3269 0 32V354.921C0 372.594 14.3269 386.921 32 386.921H326C343.673 386.921 358 372.594 358 354.921V32C358 14.3269 343.673 0 326 0H32ZM267.948 430.639C269.121 432.573 271.949 432.618 273.169 430.714C296.475 394.324 309.143 388.563 320.5 386.921H211C233.22 390.081 248.204 398.058 267.948 430.639Z" fill="#FFDEAA" />
              </svg>

              <div className="photo-frame">

                <img className="ellipse-21" src={cleantel} />
              </div>

              <div className="frame-38">
                <div className="remaining-containing">
                  Working With “Accruon Consultant” Has Been A Game-Changer For Our Business. Their Professionalism, Deep Industry Knowledge, And Commitment To Delivering Results Are Truly Commendable. Accruon Team Of Experts Provide Invaluable Guidance And Support, Helping Us Streamline Our Operations, Optimize Our Processes, And Achieve Significant Cost Savings. We Highly Recommend “Accruon Consultant” To Any Organization Seeking Top-Notch Consulting Services.
                </div>
                <svg className="vector" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path d="M4.16655 32.9333C3.18525 32.9333 2.24414 32.533 1.55026 31.8204C0.856373 31.1077 0.466552 30.1412 0.466552 29.1333L0.466551 10.1334C0.466551 7.44583 1.50607 4.86837 3.35643 2.968C5.20679 1.06763 7.71641 1.84397e-05 10.3332 1.82109e-05L10.3332 2.53335C8.37062 2.53335 6.4884 3.33406 5.10063 4.75934C3.71286 6.18462 2.93322 8.11771 2.93322 10.1334L2.93322 17.7334L11.5666 17.7333C12.5479 17.7334 13.489 18.1337 14.1828 18.8463C14.8767 19.559 15.2666 20.5255 15.2666 21.5333L15.2666 29.1333C15.2666 30.1412 14.8767 31.1077 14.1828 31.8204C13.489 32.533 12.5479 32.9333 11.5666 32.9333L4.16655 32.9333ZM21.4332 32.9333C20.4519 32.9333 19.5108 32.533 18.8169 31.8204C18.123 31.1077 17.7332 30.1412 17.7332 29.1333L17.7332 10.1334C17.7332 7.44582 18.7727 4.86837 20.6231 2.968C22.4735 1.06763 24.9831 1.69302e-05 27.5999 1.67014e-05L27.5999 2.53335C25.6373 2.53335 23.7551 3.33406 22.3673 4.75934C20.9795 6.18461 20.1999 8.1177 20.1999 10.1334L20.1999 17.7333L28.8332 17.7333C29.8145 17.7333 30.7556 18.1337 31.4495 18.8463C32.1434 19.559 32.5332 20.5255 32.5332 21.5333L32.5332 29.1333C32.5332 30.1412 32.1434 31.1077 31.4495 31.8204C30.7556 32.533 29.8145 32.9333 28.8332 32.9333L21.4332 32.9333Z" fill="#7D5700"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>





        <div className="row row-1">
          <div className="column-1">
            <div className="testimonial-card">
              <svg className="union" viewBox="0 0 358 433" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M32 0C14.3269 0 0 14.3269 0 32V354.921C0 372.594 14.3269 386.921 32 386.921H326C343.673 386.921 358 372.594 358 354.921V32C358 14.3269 343.673 0 326 0H32ZM267.948 430.639C269.121 432.573 271.949 432.618 273.169 430.714C296.475 394.324 309.143 388.563 320.5 386.921H211C233.22 390.081 248.204 398.058 267.948 430.639Z" fill="#FFDEAA" />
              </svg>

              <div className="photo-frame">

                <img className="ellipse-22" src={op3} />
              </div>

              <div className="frame-38">
                <div className="remaining-containing">
                  I Cannot Thank “Accruon Consultant” Enough For Their Exceptional Consulting Services. The Dedication, Expertise, And Collaborative Approach Of The Entire Team Have Exceeded Our Expectations. Their Strategic Insights And Innovative Solutions Have Transformed Our Business. With Their Guidance, We Were Able To Identify
                  Untapped Opportunities, Enhance Our Market Positioning, And Drive Substantial Growth.
                </div>
                <svg className="vector" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" >
                  <path d="M4.16655 32.9333C3.18525 32.9333 2.24414 32.533 1.55026 31.8204C0.856373 31.1077 0.466552 30.1412 0.466552 29.1333L0.466551 10.1334C0.466551 7.44583 1.50607 4.86837 3.35643 2.968C5.20679 1.06763 7.71641 1.84397e-05 10.3332 1.82109e-05L10.3332 2.53335C8.37062 2.53335 6.4884 3.33406 5.10063 4.75934C3.71286 6.18462 2.93322 8.11771 2.93322 10.1334L2.93322 17.7334L11.5666 17.7333C12.5479 17.7334 13.489 18.1337 14.1828 18.8463C14.8767 19.559 15.2666 20.5255 15.2666 21.5333L15.2666 29.1333C15.2666 30.1412 14.8767 31.1077 14.1828 31.8204C13.489 32.533 12.5479 32.9333 11.5666 32.9333L4.16655 32.9333ZM21.4332 32.9333C20.4519 32.9333 19.5108 32.533 18.8169 31.8204C18.123 31.1077 17.7332 30.1412 17.7332 29.1333L17.7332 10.1334C17.7332 7.44582 18.7727 4.86837 20.6231 2.968C22.4735 1.06763 24.9831 1.69302e-05 27.5999 1.67014e-05L27.5999 2.53335C25.6373 2.53335 23.7551 3.33406 22.3673 4.75934C20.9795 6.18461 20.1999 8.1177 20.1999 10.1334L20.1999 17.7333L28.8332 17.7333C29.8145 17.7333 30.7556 18.1337 31.4495 18.8463C32.1434 19.559 32.5332 20.5255 32.5332 21.5333L32.5332 29.1333C32.5332 30.1412 32.1434 31.1077 31.4495 31.8204C30.7556 32.533 29.8145 32.9333 28.8332 32.9333L21.4332 32.9333Z" fill="#7D5700"
                  />
                </svg>
              </div>
            </div>
          </div></div>
        <div>
          <div className="row row-1">
            <div className="column-1">
              <div className="testimonial-card">
                <svg className="union" viewBox="0 0 358 433" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M32 0C14.3269 0 0 14.3269 0 32V354.921C0 372.594 14.3269 386.921 32 386.921H326C343.673 386.921 358 372.594 358 354.921V32C358 14.3269 343.673 0 326 0H32ZM267.948 430.639C269.121 432.573 271.949 432.618 273.169 430.714C296.475 394.324 309.143 388.563 320.5 386.921H211C233.22 390.081 248.204 398.058 267.948 430.639Z" fill="#FFDEAA" />
                </svg>

                <div className="photo-frame">

                  <img className="ellipse-23" src={steelmart} />
                </div>

                <div className="frame-38">
                  <div className="remaining-containing">Choosing Accruon Consultant Was The Best Decision We Made For Our Organization. They Conducted A Thorough Analysis Of Our Business, Identified Areas For Improvement, And Provided Practical Recommendations. Their Actionable Strategies Helped Us Overcome Challenges, Improve Our Efficiency, And Achieve Measurable Results. We Are Incredibly Grateful For Their Professionalism, Integrity, And Unwavering Support Throughout The Engagement..
                  </div>
                  <svg className="vector" width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path d="M4.16655 32.9333C3.18525 32.9333 2.24414 32.533 1.55026 31.8204C0.856373 31.1077 0.466552 30.1412 0.466552 29.1333L0.466551 10.1334C0.466551 7.44583 1.50607 4.86837 3.35643 2.968C5.20679 1.06763 7.71641 1.84397e-05 10.3332 1.82109e-05L10.3332 2.53335C8.37062 2.53335 6.4884 3.33406 5.10063 4.75934C3.71286 6.18462 2.93322 8.11771 2.93322 10.1334L2.93322 17.7334L11.5666 17.7333C12.5479 17.7334 13.489 18.1337 14.1828 18.8463C14.8767 19.559 15.2666 20.5255 15.2666 21.5333L15.2666 29.1333C15.2666 30.1412 14.8767 31.1077 14.1828 31.8204C13.489 32.533 12.5479 32.9333 11.5666 32.9333L4.16655 32.9333ZM21.4332 32.9333C20.4519 32.9333 19.5108 32.533 18.8169 31.8204C18.123 31.1077 17.7332 30.1412 17.7332 29.1333L17.7332 10.1334C17.7332 7.44582 18.7727 4.86837 20.6231 2.968C22.4735 1.06763 24.9831 1.69302e-05 27.5999 1.67014e-05L27.5999 2.53335C25.6373 2.53335 23.7551 3.33406 22.3673 4.75934C20.9795 6.18461 20.1999 8.1177 20.1999 10.1334L20.1999 17.7333L28.8332 17.7333C29.8145 17.7333 30.7556 18.1337 31.4495 18.8463C32.1434 19.559 32.5332 20.5255 32.5332 21.5333L32.5332 29.1333C32.5332 30.1412 32.1434 31.1077 31.4495 31.8204C30.7556 32.533 29.8145 32.9333 28.8332 32.9333L21.4332 32.9333Z" fill="#7D5700"
                    />
                  </svg>
                </div>
              </div>
            </div>

          </div>
        </div>



      </Carousel>

    </section>


  </>)
}
export default OurTestimonialsPage;