
import './App.css';
import { Navigate, Route, Routes,useLocation } from 'react-router-dom';
import LandingHomePageConsultants from './routes/homepage';
import ThankYouPage from './routes/thankyou-page';

function App() {
  const location = useLocation();
  if(location.pathname=="/"){
      document.title = location.pathname.split('/').pop()+ "Accruon consultants";
  }
  else if((location.pathname =="/")||( location.pathname =="/Thank-you")){
    document.title = location.pathname.split('/').pop()+ "|Accruon consultants";
  }
  else {
    document.title = "pag not found"+ "|Accruon consultants";
  }
  return (
   <>
    <Routes>
    <Route path="/" element={<LandingHomePageConsultants />} />
    <Route path="Thank-you" element={<ThankYouPage/>} />
    <Route path="*" element={<LandingHomePageConsultants />} />
    </Routes>
   </>
  );
}


export default App;
