
import consultantLog from "../../../assests/images/accruon-consultants-logo.png"
import call1 from "../../../assests/images/call-1.png"
import gmail from "../../../assests/images/mail.svg"
const NavBarPage=()=>{
    return(<>
     {/* <!-- ========= HEADER ==========  --> */}
    <header className="l-header" id="header">
      <nav className="nav bd-container">
        <a href="#" className="nav__logo"><img src={consultantLog}/></a>

        <div className="nav__menu" id="nav-menu">
        <a href="tel:+971 52913 7700" style={{textDecoration: 'none'}}>  <p style={{color:"white"}}><img src={call1}/>&nbsp; +971 52913 7700</p></a>
         <a href="mailto:mail@accruonconsultants.com" style={{textDecoration: 'none'}}><p style={{color:"white"}}><img src={gmail}/>&nbsp; mail@accruonconsultants.com</p></a>
         

         
        </div>

    </nav>
    </header>
    </>)
}
export default NavBarPage;